import React, { createContext, useContext, useEffect, useState } from 'react';
import { IProvidersProps } from "../interfaces/providers.interface";
import { io, Socket } from "socket.io-client";
import { ISocketContextProps } from "../interfaces/socket-provider.interface";
import { notifyContext } from "../NotifyProvider";
import { INotify } from "../interfaces/notify-provider.interface";
import { userContext } from "../UserProvider";

const socket: Socket = io(`https://localhost:4000/`, {
  path: '/io',
  autoConnect: false,
});

export const socketContext = createContext<ISocketContextProps>({
  isConnected: false,
  socketConnect: () => {},
  socketDisconnect: () => {},
  socketEmit: (path, data) => {},
  socketOn: (path, fn) => {},
  socketOff: (path) => {},
  socketId: '',
});

export function SocketProvider({ children }: IProvidersProps) {
  const { setNotify } = useContext(notifyContext);
  const { Provider } = socketContext;
  const { getUserId, getToken } = useContext(userContext);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const userId = getUserId();
  const token = getToken();

  useEffect(() => {
    socket.on('connect', () => {
      socket.emit('auth', {
        userId,
        token,
      });
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.on('notify', notify => {
      setNotify(notify as INotify[]);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('notify');
    };
  }, []);

  return (
    <Provider value={{
      isConnected,
      socketConnect: () => socket.connect(),
      socketDisconnect: () => socket.disconnect(),
      socketEmit: (path, data) => socket.emit(path, data),
      socketOn: (path, fn) => socket.on(path, fn),
      socketOff: (path) => socket.off(path),
      socketId: socket.id,
    }}>
      {children}
    </Provider>
  );
}
