import React from 'react';
import { PagesProvider } from './PagesProvider';
import { ModalProvider } from './ModalProvider';
import { NotifyProvider } from "./NotifyProvider";
import { RequestProvider } from "./RequestProvider";
import { SocketProvider } from "./SocketProvider";
import { TooltipProvider } from "./TooltipProvider";
import { UserProvider } from "./UserProvider";
import { GameProvider } from "./GameProvider";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { IProvidersProps } from './interfaces/providers.interface';

export default function Providers({ children }:IProvidersProps) {
  return (
    <UserProvider>
      <ModalProvider>
        <TooltipProvider>
          <RequestProvider>
            <GoogleOAuthProvider clientId={'868121232856-ba7ar64m7031k1e7i67l3j3gm01m89hm.apps.googleusercontent.com'}>
              <NotifyProvider>
                <PagesProvider>
                  <SocketProvider>
                    <GameProvider>
                      {children}
                    </GameProvider>
                  </SocketProvider>
                </PagesProvider>
              </NotifyProvider>
            </GoogleOAuthProvider>
          </RequestProvider>
        </TooltipProvider>
      </ModalProvider>
    </UserProvider>
  );
}
