import { IUseRequestProps } from "./interfaces/useRequest.interface";
import axios from 'axios';

import { useContext, useState } from "react";
import { callIsFn } from '../lib/callIsFn';
import { userContext } from "../providers/UserProvider";
import { useNavigate } from "react-router-dom";

export function useRequest() {
  const navigate = useNavigate();
  const { getToken } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const token = getToken();

  const createOptions = <P>(path: string, method: string = 'GET', data?: P) => {
    const config: { [key: string]: any } = {
      method: method,
      url: `https://localhost:4000/${path}`,
      headers: {
        'content-type': 'application/json',
      },
    };

    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    if (data) {
      config.data = data;
    }

    return config;
  };

  const request = <P, D>({ path, data, success, method, failure }: IUseRequestProps<P, D>) => {
    if (loading) {
      return;
    }

    setLoading(true);
    const options = createOptions<P>(path, method, data);

    axios
      .request(options)
      .then(({ data }) => {
        setLoading(false);
        success(data);
      })
      .catch(({ response }) => {
        setLoading(false);
        const data = (response && response.data) || response;
        if (data && typeof data.message === 'string') {
          data.message = [data.message];
        }
        if (data && data.keyValue && typeof data.keyValue === 'object') {
          data.message = Object.entries(data.keyValue).map(([name, value]) => (
            `${name} ${value} уже существует`
          ));
        }

        if (response && response.statusText && response.statusText === 'Unauthorized') {
          navigate('/logout');
        }

        // setNotify({
        //   header: data.error || 'Ошибка',
        //   text: data.message.join(', '),
        //   code: data.statusCode,
        //   type: ENotifyType.error,
        // });
        // setNotify([{
        //   senderId: string;
        //   notifyId: string;
        //   title: string;
        //   msg: string;
        //   type: string;
        //   createTime: number;
        // }]);
        callIsFn(failure, response);
      })
      .finally(() => setLoading(false));
  }

  return { request, loading };
}